:root {
  --colorNeutralBackground1: #ffffff;
  --colorPaletteVioletBackground1: #1e004d;
  --colorPaletteVioletBackground2: #7562fc;
  --colorPaletteVioletBackground3: #6e31ff;
  --colorBrandForeground1: #1477f8;
  --colorNeutralForegroundInverted1: #f5f6fa;
  --colorNeutralForegroundInverted2: #efefef;
  --colorNeutralForegroundInverted3: #EDF0FF;
  --colorNeutralForeground2: #707882;
  --colorNeutralForeground5: #959ba2;
  --colorNeutralForeground7: #bdbdbd;
  --colorNeutralForeground4: #f1f3f8;
  --colorNeutralForeground1: #3e4450;
  --colorNeutralForeground9: #E0E0E0;
  --colorNeutralForeground10: #D9DDE6;
  --colorNeutralForeground11: #A2A2A2;
  --colorNeutralBackgroundInverted2: #10141f;
  --colorNeutralBackgroundInverted3: #2a2b2f;
  --colorNeutralBackground3: #d9dde7;
  --colorNeutralBackground5: #eaeaea;
  --colorPalleteLightBlue: #eeefff;
  --colorNeutralForeground3: #323232;
  --greenBorderColor: #83e1ab;
  --grenBackgroundColor: #dbffea;
  --colorPaletteRedForeground1: #fa6b6b;
  --colorPaletteRedForeground2: #bd0202;
  --colorPaletteRedForeground3: #FFCACA;
  --colorPaletteGreenBackground2: #07c357;
  --colorPalettePurpleBackground1: #a19afc;
  --colorPalettePurpleBackground2: #D8E2FF;
  --colorPalettePurpleBackground3: #E2D6FF;
  --colorPaletteBlueBackground1: #cdd3ec;
  --colorNeutralBackground4: #f5f6fa;
  --graySelectBoxBorder: #cdd3ec;
  --colorShadowInverted1: rgba(61, 66, 77, 0.09);
  --colorShadowInverted2: rgba(0, 0, 0, 0.6);
  --colorShadowInverted3: rgba(0, 0, 0, 0.2);
  --colorShadowInverted4: rgba(0, 0, 0, 0.8);
  --colorShadowInverted5: rgba(0, 0, 0, 0.38);
  --colorShadowInverted6: rgba(211, 47, 47, 0.12);
  --colorShadowInverted7: rgba(0, 0, 0, 0.60);
  --colorPaletteBlueBackground2: #e4e9ff;
  --colorNeutralForeground6: #a9afc6;
  --grenBackgroundColor2: #f5feff;
  --colorPaletteBlueBackground3: #e4e9ff;
  --colorNeutralForeground8: #f9fafc;
  --colorNeutralForeground12: #9399a1;
}
